import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import NewsLayout from '../components/NewsLayout'

const NewsTemplate = ({ data, pageContext }) => {
  const {
    markdownRemark: news,
    site: {
      siteMetadata: { title },
    },
  } = data

  const { next, previous } = pageContext

  return (
    <Layout>
      <NewsLayout
        content={news.html}
        contentComponent={HTMLContent}
        description={news.frontmatter.description}
        date={news.frontmatter.date}
        helmet={
          <Helmet titleTemplate={`%s | ${title}`}>
            <title>{`${news.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${news.frontmatter.description}`}
            />
            <html className="news-detail" lang="ja" />
          </Helmet>
        }
        tags={news.frontmatter.tags}
        title={news.frontmatter.title}
        next={next}
        previous={previous}
      />
    </Layout>
  )
}

NewsTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default NewsTemplate

export const pageQuery = graphql`
  query GetNewsByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        description
      }
    }
  }
`
