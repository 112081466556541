import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Content from '../components/Content'

const NewsLayout = ({
  content,
  contentComponent,
  title,
  description,
  date,
  helmet,
  next,
  previous,
}) => {
  const PostContent = contentComponent || Content

  return (
    <>
      {helmet || ''}

      <div className="common-header clearfix detail">
        <p className="category pc-only">トピックス</p>
        <h1>{title}</h1>
        <p className="category sp-only">トピックス</p>
      </div>
      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <div className="breadcrumb">
              <li>
                <a href="/">トップページ</a>
              </li>
              /
              <li>
                <Link to={`/news`}>トピックス</Link>
              </li>
              /<li>{title}</li>
            </div>

            <div className="date">{date}</div>

            <p>{description}</p>
          </div>
        </div>
        <PostContent content={content} className="news-content" />

        <div className="row">
          <div className="pagenation-area">
            <div className="box">
              <div className="wrapper">
                {previous && (
                  <>
                    <div className="post">前のトピックス</div>
                    <Link to={previous.fields.slug}>
                      <span className="date">{previous.frontmatter.date}</span>
                      <span className="title">
                        {previous.frontmatter.title}
                      </span>
                    </Link>
                  </>
                )}
              </div>
            </div>

            <div className="box">
              <div className="wrapper">
                {next && (
                  <>
                    <div className="post">次のトピックス</div>
                    <Link to={next.fields.slug}>
                      <span className="date">{next.frontmatter.date}</span>
                      <span className="title">{next.frontmatter.title}</span>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

NewsLayout.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

export default NewsLayout
